import React, { useEffect } from "react";
import { Route, Switch } from 'react-router-dom';
import Home from "./components/home/home";
import ProjectsHistory from "./components/projects/projects_history";
import ReactGa from 'react-ga';

export default function Routes() {

    useEffect(() => {
      ReactGa.initialize('UA-214720356-1');
    
        // to report page View
        //ReactGa.pageview('/');
        ReactGa.pageview(window.location.pathname + window.location.search)
    }, [])
    return (
      <Switch>
        <Route exact path={`/solutions`} component={Home} />
        <Route exact path={`/solutions/histprojects`} component={ProjectsHistory} />
  
      </Switch>
    );
  }