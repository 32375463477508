import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Button, ButtonGroup, OverlayTrigger, Tooltip, Image, ToggleButton } from 'react-bootstrap';
import Select, { OptionTypeBase, Styles, ValueType } from 'react-select';
import { reactSelectCustomStyles } from './project_helper_funcs';
import { useMediaQuery } from 'react-responsive';
import {FaArrowCircleUp} from 'react-icons/fa';
import makeAnimated from 'react-select/animated';
import Footer from '../footer';
import projectData from '../../project_content.json';
import '../../corestyles.css'
import './projects_history.css'

/* IMAGES - Since REQUIRE doesn't work, we'll set a reference then do a check */
import HeadshotImage from '../../assets/NickBagalay_headshot.jpg';

const radios = [
        { name: 'Must Contain', value: '1' },
        { name: 'At Least Contain', value: '2' },
    ];

export interface ReactSelectOptValues {
    value: any;
    label: any;
}

interface PropDisplaySearchResultTags {
    keywordTagItems: Array<SearchResultsKeywordTags> | undefined | null
}

interface PropDisplaySearchResultSection {
    sectionItems: Array<SearchResultsSectionDetails> | undefined | null;
}

interface GridSearchResultsTop {
    Title: string;
    Company: string | null;
    StartDate: string;
    EndDate: string;
    MilestoneDates: Array<SearchResultsMilestoneDates> | undefined;
    ProjectUrl: string | undefined | null;
    KeywordTags: Array<SearchResultsKeywordTags> | null | undefined;
    SectionDetails: Array<SearchResultsSectionDetails>;
}

interface SearchResultsMilestoneDates {
    StartDate: Date;
    EndDate: Date;
    Summary: string;
}

interface SearchResultsKeywordTags {
    TagName: string;
    TagMatch?: boolean | undefined;
}

interface SearchResultsSectionDetails {    
    Description: string;
    SupportItems: Array<SearchResultsSectionDetailsSupItems> | undefined;
    SectionTitle?: string | null;
}

interface SearchResultsSectionDetailsSupItems {
    ItemDetail: string;
}


const ProjectsHistory = () => {
    const [attributeTypeOptions, setAttributeTypeOptions] = useState<ReactSelectOptValues[]>();
    const [selectedSearch, setSelectedSearch] = useState<ValueType<ReactSelectOptValues, true>>();
    const [radioValue, setRadioValue] = useState<string>('1');
    const [projectSearchResults, setProjectSearchResults] = useState<Array<GridSearchResultsTop>>([]);
    const [showScroll, setShowScroll] = useState<boolean>(true);

    useEffect(() => {            
        window.addEventListener('scroll', checkScrollTop)
        return function cleanup() {
            window.removeEventListener('scroll', checkScrollTop);
        }
    })
    
    useEffect(() => {
        getAvailableTagsOptions();
        //checkScrollProjTop();       // Remove if you uncomment the showScrollProj condition

        getSearchResults([]);
    }, [])

    const checkScrollTop = () => {
        setShowScroll(true)
        /*
        if (!showScroll && window.pageYOffset > 300){
            setShowScroll(true)
        } else if (showScroll && window.pageYOffset <= 300){
            setShowScroll(false)
        }
        */
    };

    const scrollTop = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    };

    const animatedComponents = makeAnimated();

    const isTabletOrMobileDevice = useMediaQuery({
        query: '(max-device-width: 1224px)'
    })

    const comparator = (item1: any, item2: any) => {
        // Decending order.
        var item1Date = new Date(item1.StartDate).valueOf();
        var item2Date = new Date(item2.StartDate).valueOf();

        return item2Date - item1Date;
    }

    const getSearchResults = (selectedSearchSet: ValueType<ReactSelectOptValues, true>) => {
        var selectedSearchInclude: Array<string> = [];
        var finalSearchResults: Array<GridSearchResultsTop> = [];

        if (selectedSearchSet !== undefined && selectedSearchSet !== null) {
            selectedSearchSet.forEach((val) => {
                selectedSearchInclude.push(val.value);
            })
        }

        // We need to get the selected options and build a select statement
        if (selectedSearchInclude.length > 0) {

            // Contains Option: If Must contain, the length of the tag search Must equal
            // what was specified. At least contain means only one option
            var lengthOfSearch: number = 0;
            if (radioValue === `1`) {
                lengthOfSearch = selectedSearchInclude.length;
            } else {
                // Since this is the contains, we just need to find ONE match up
                lengthOfSearch = 1;
            }

            var filterSearch = projectData.Projects.filter((projItems) => {
                var filterByKeyword = projItems.KeywordTags.filter((keywordItem) => {
                    return selectedSearchInclude.includes(keywordItem.TagName);
                });
                
                // For all the keywords selected, we want to return the results
                // that match ALL the tags selected
                //if (filterByKeyword.length >= selectedSearchSet.length) {
                if (filterByKeyword.length >= lengthOfSearch) {
                    return projItems;
                }
            })
            
            for (let index = 0; index < filterSearch.length; index++) {
                var sectionDetFinal: Array<SearchResultsSectionDetails> | null = [];

                for (let subindex = 0; subindex < filterSearch[index].SectionDetails.length; subindex++) {
                    var finalSubJson:SearchResultsSectionDetails = {
                        SectionTitle: filterSearch[index].SectionDetails[subindex]?.SectionTitle,
                        Description: filterSearch[index].SectionDetails[subindex].Description,
                        SupportItems: filterSearch[index].SectionDetails[subindex]?.SupportItems                        
                    }
                    sectionDetFinal.push(finalSubJson)
                }

                var finalJson: GridSearchResultsTop = {
                    Company: filterSearch[index].Company,
                    EndDate: filterSearch[index].EndDate,
                    KeywordTags: filterSearch[index].KeywordTags,
                    MilestoneDates: undefined,
                    ProjectUrl: null,
                    SectionDetails: filterSearch[index].SectionDetails.length > 0 ? sectionDetFinal : [],
                    StartDate: filterSearch[index].StartDate,
                    Title: filterSearch[index].Title
                }
                finalSearchResults.push(finalJson);
            }
            

        } else {
            for (let index = 0; index < projectData.Projects.length; index++) {
                var sectionDetFinal: Array<SearchResultsSectionDetails> | null = [];

                for (let subindex = 0; subindex < projectData.Projects[index].SectionDetails.length; subindex++) {
                    var finalSubJson: SearchResultsSectionDetails = {
                        SectionTitle: projectData.Projects[index].SectionDetails[subindex]?.SectionTitle,
                        Description: projectData.Projects[index].SectionDetails[subindex].Description,
                        SupportItems: projectData.Projects[index].SectionDetails[subindex]?.SupportItems
                    }
                    sectionDetFinal.push(finalSubJson)
                }

                var finalJson: GridSearchResultsTop = {
                    Company: projectData.Projects[index].Company,
                    EndDate: projectData.Projects[index].EndDate,
                    KeywordTags: projectData.Projects[index].KeywordTags,
                    MilestoneDates: undefined,
                    ProjectUrl: null,
                    SectionDetails: projectData.Projects[index].SectionDetails.length > 0 ? sectionDetFinal : [],
                    StartDate: projectData.Projects[index].StartDate,
                    Title: projectData.Projects[index].Title
                }
                finalSearchResults.push(finalJson);
            }

        }
        
        /* --KEYWORD TAG MATCH: If a match is found, add a bool flag to Tags */
        for (var i = 0; i < finalSearchResults.length; i++) {
            var newHighlightedTags: Array<SearchResultsKeywordTags> = [];
            var currentFilterTags: Array<SearchResultsKeywordTags> | null | undefined = finalSearchResults[i].KeywordTags;

            if (currentFilterTags === undefined || currentFilterTags === null) continue;

            for (var j = 0; j < currentFilterTags.length; j++) {
                var currentTagRow = currentFilterTags[j];
                var includedRecs = selectedSearchInclude.includes(currentFilterTags[j].TagName);

                if (includedRecs) {
                    currentTagRow["TagMatch"] = true;
                    newHighlightedTags.push(currentTagRow);
                } else {
                    currentTagRow["TagMatch"] = false;
                    newHighlightedTags.push(currentTagRow);
                }
            }
            finalSearchResults[i].KeywordTags = newHighlightedTags;
        }

        /* There is a default template project. We don't need it */
        finalSearchResults = finalSearchResults.filter((item) => {
            return item.Title !== `TEMPLATE EXAMPLE`;
        })

        var finalSortedFilter = finalSearchResults.sort(comparator);
        setProjectSearchResults(finalSortedFilter);
        //return finalSortedFilter;
    }

    const DisplaySearchResults = React.forwardRef(() => {
        
        return (            
            <>
            {projectSearchResults
                .map((rowItem, rowIndex) => (
                <>
                <div className="content-title">
                    {rowItem.Title}
                </div>
                <div className="content-border"> </div>
                
                <div className="project-daterow">
                    {`${new Date(rowItem.StartDate).toLocaleString('en-US', { month: "long", year: "numeric", timeZone: 'UTC' })} To ${(new Date(rowItem.EndDate) > new Date()) ? 'Present' :  new Date(rowItem.EndDate).toLocaleString('en-US', { month: "long", year: "numeric", timeZone: 'UTC' })}`}
                </div>
                <div className="keywordtag-top">
                    <DisplaySearchResultTags 
                        keywordTagItems={rowItem.KeywordTags} />
                </div>
                <div className="content-subborder"> </div>
                
                <div className="project-results-body-top">
                    <DisplaySearchResultSection
                        sectionItems={rowItem.SectionDetails} />
                </div>
                </>
            ))}
            </>
        )
    });

    const DisplaySearchResultSection = React.forwardRef((propRow: PropDisplaySearchResultSection, refRow) => {
        const [sectionItemSet, setSectionItemSet] = useState<Array<SearchResultsSectionDetails>>([]);
        useEffect(() => {
            if (propRow?.sectionItems) {
                setSectionItemSet(propRow.sectionItems);
            }            
        }, [propRow?.sectionItems])

        return (
            <>
            {sectionItemSet
            .map((rowSecDetItem, rowIndex) => (
                <>
                {(rowSecDetItem.SectionTitle !== undefined &&
                    rowSecDetItem.SectionTitle !== '' &&
                    rowSecDetItem.SectionTitle !== null) ?
                <div className="content-body-section-title">
                    {rowSecDetItem.SectionTitle}
                </div>
                :<></>}

                <div className="content-body">
                {rowSecDetItem.Description}
                </div>

                {(rowSecDetItem.SupportItems !== undefined &&
                    rowSecDetItem.SupportItems.length > 0) ?
                <div className="content-body">
                    <ul style={{paddingTop: '10px'}}>
                    {rowSecDetItem.SupportItems
                    .map((rowItemList, rowIndexList) => (
                        <>
                        <li>{rowItemList.ItemDetail}</li>
                        </>
                    )) }
                    </ul>
                </div>
                : <><div style={{paddingBottom: '10px'}}> </div></>}
                </>
            ))}
            </>
        )
    })

    const DisplaySearchResultTags = React.forwardRef((propRow: PropDisplaySearchResultTags, refRow) => {
        const [keywordItem, setKeywordItem] = useState<Array<SearchResultsKeywordTags>>([]);

        useEffect(() => {
            if (propRow?.keywordTagItems) {
                setKeywordItem(propRow.keywordTagItems);
            }            
        }, [propRow?.keywordTagItems])
        
        const getKeywordTagDescription = (tagValue: any) => {
            var results = projectData.AvailableTags.filter((valueDesc) => {
                return valueDesc.TagName === tagValue;
            })
            if (results.length > 0) {
                return results[0].Description;
            }

            return ``;
        }

        return (
            <>
            {keywordItem
            .sort((a, b) => { return ((a.TagName > b.TagName) ? 1 : -1 ) })
            .map((rowItem, rowIndex) => (
                (rowItem.TagMatch === true) ?
                <div style={{paddingRight: '10px', paddingBottom: '5px'}}>
                    <OverlayTrigger
                        key={rowItem.TagName}
                        placement={`top`}
                        overlay={
                            <Tooltip id={`tooltip-${rowItem.TagName}`}>
                            {getKeywordTagDescription(rowItem.TagName)}
                            </Tooltip>
                    }>
                        <Button variant="warning" style={{padding: '5px', paddingTop: '0px', paddingBottom: '0px'}}>{rowItem.TagName}</Button>
                    </OverlayTrigger>
                </div>
                :<div style={{paddingRight: '10px', paddingBottom: '5px'}}>
                    <OverlayTrigger
                        key={rowItem.TagName}
                        placement={`top`}
                        overlay={
                            <Tooltip id={`tooltip-${rowItem.TagName}`}>
                            {getKeywordTagDescription(rowItem.TagName)}
                            </Tooltip>
                    }>
                        <Button variant="outline-warning" style={{padding: '5px', paddingTop: '0px', paddingBottom: '0px'}}>{rowItem.TagName}</Button>
                    </OverlayTrigger>
                </div>
            ))}
            </>
        )
    });

    const getAvailableTagsOptions = () => {
        var finalOptions: Array<ReactSelectOptValues> = [];

        projectData.AvailableTags.forEach(item => {
            var tempOpt = {
                value: item.TagName,
                label: item.TagName
            }

            finalOptions.push(tempOpt);
        });
        
        setAttributeTypeOptions(finalOptions.sort((a, b) => { 
            return ((b.value < a.value) ? 1 : -1);
        }));
    }
    
    return (
        <>
        <div className="page-container footer-at-bottom">
            <div className="page-introduction" style={{paddingBottom: `20px`}}>
                <div className="introduction-picture">
                    <Image src={HeadshotImage} roundedCircle />
                </div>

                <div className="introduction-details">
                    <div className="introduction-title">
                        {`Projects and History`}
                    </div>
                    <div className="introduction-details-border"> </div>

                    <div className="introduction-description">
                        {`${projectData.ProjHistoryDetails}`}
                    </div>

                </div>
            </div>

            <div className="bannersec-top">
                <div className="bannersec-title">
                    <div>
                        <h3>Search</h3>
                    </div>
                    <div className="section-border">
                    </div>
                </div>
                <div className="bannersec-body">
                    <div className="searchbar-containbtn-top" style={{paddingBottom: `10px`}}>
                        <ButtonGroup toggle>
                            {radios.map((radio, idx) => (
                            <ToggleButton
                                key={idx}
                                size="sm"
                                type="radio"
                                variant="outline-warning"
                                name="radio"
                                value={radio.value}
                                checked={radioValue === radio.value}
                                onChange={(e) => setRadioValue(e.currentTarget.value)}
                            >
                                {radio.name}
                            </ToggleButton>
                            ))}
                        </ButtonGroup>
                    </div>
                    <div className="searchbar-top">
                        <div className="searchbar-select">
                            <Select                            
                                isMulti
                                isSearchable={!isTabletOrMobileDevice}
                                closeMenuOnSelect={false}
                                styles={reactSelectCustomStyles}     
                                autoComplete='off'
                                className="basic-multi-select"
                                classNamePrefix="select"
                                name="attributetypes"
                                options={attributeTypeOptions}
                                onChange={(val) => {
                                    setSelectedSearch(val);
                                }}
                                value={selectedSearch} />

                        </div>
                        <div>
                            <Button variant="warning" onClick={(val) => {
                                getSearchResults(selectedSearch);
                            }}>Search</Button>
                        </div>
                    </div>
                    <div className="search-count-top">
                        <div className="search-count-total">
                            {projectSearchResults === undefined ? 0 : projectSearchResults.length}
                        </div>
                        <div className="search-count-total-desc">
                            {`Records found`}
                        </div>
                    </div>
                </div>
            </div>

            <div id="searchresults" className="page-introduction" style={{paddingTop: `12px`}}>
                <div className="content-title-top">
                    <div><h3>SEARCH RESULTS</h3></div>
                    <div className="section-border"></div>
                </div>
                <div className="content-body-top">
                    {projectSearchResults !== undefined ?
                    <DisplaySearchResults /> : <></>}
                </div>
            </div>



        </div>

        <div>
            <Footer />
        </div> 

        <div>
            <FaArrowCircleUp className="scrollTop" onClick={scrollTop} style={{height: 50, display: showScroll ? 'flex' : 'none'}}/>
        </div>
        </>
    )
}

export default ProjectsHistory;