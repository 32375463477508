import React, { forwardRef } from "react";
import { Image } from 'react-bootstrap';
import './footer.css';

import GitHubImg from '../assets/Github.png';
import EmailImg from '../assets/Email.png';
import LinkedInImg from '../assets/LinkedIn.png';

const Footer = forwardRef(() => {

    return (
        <div className="content-top">
            <div className="content-image-top">
                <div className="image-properties">
                    <a href="https://www.linkedin.com/in/nick-bagalay/">
                        <Image src={LinkedInImg} thumbnail />
                    </a>
                </div>
                <div className="image-properties">
                    <a href="https://github.com/nbagalay">
                        <Image src={GitHubImg} thumbnail />
                    </a>
                </div>
                <div className="image-properties">
                <a href="mailto:nick.bagalay@gmail.com?subject=Inquiry%20About%20Nick%20Bagalay">
                        <Image src={EmailImg} thumbnail />
                    </a>
                </div>
            </div>
            <div className="content-copyright">
                {`© Copyright ${new Date().toLocaleDateString('en-US', { year: "numeric" })} Nick Bagalay • Design by Bagalay Solutions`}
            </div>
        </div>
    )
})

export default Footer;