import React from 'react';
import ReactDOM from 'react-dom';
import "bootswatch/dist/sandstone/bootstrap.min.css"; 
import TopNav from './top_nav';
import Routes from './routes';
import { BrowserRouter, HashRouter, Router } from 'react-router-dom';
import { createBrowserHistory } from "history";
import reportWebVitals from './reportWebVitals';

export const history = createBrowserHistory({
  basename: `${process.env.PUBLIC_URL}/`
});

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <TopNav />
      <Routes />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
