import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Button, Container, Image } from 'react-bootstrap';
import { Link, animateScroll as scroll } from "react-scroll";
import {FaArrowCircleUp} from 'react-icons/fa';
import _, { isUndefined } from "lodash";
import HomePointOfInterest from './components/point_of_interest';
import resumeData from '../../resume_content.json';
import Footer from '../footer';

/* IMAGES - Since REQUIRE doesn't work, we'll set a reference then do a check */
import HeadshotImage from '../../assets/NickBagalay_headshot.jpg';

import { useMediaQuery } from 'react-responsive';
import '../../corestyles.css'
import { SkillsGraph } from './components/skills';

export default function Home() {
    const [showScroll, setShowScroll] = useState<boolean>(false);

    useEffect(() => {            
        window.addEventListener('scroll', checkScrollTop)
        return function cleanup() {
            window.removeEventListener('scroll', checkScrollTop);
        }
    })

    useEffect(() => {
        changeSkillsChart()
    }, [])

    const checkScrollTop = () => {
        if (!showScroll && window.pageYOffset > 300){
            setShowScroll(true)
        } else if (showScroll && window.pageYOffset <= 300){
            setShowScroll(false)
        }
    };

    const scrollTop = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    };

    const changeSkillsChart = () => {
        SkillsGraph(375, 1050, resumeData.Skills.sort(comparatorSkills));
    }

    const comparatorSkills = (item1: any, item2: any) => {
        // Decending order.
        return (item1.Rating - item2.Rating);
    }

    const DisplayAboutMeParagraph = React.forwardRef(() => {
        
        return (
            <>
            {resumeData.AboutMeBio
                .map((rowItem, rowIndex) => (
                    <>
                    <p>{rowItem.text}</p>
                    </>
                )
            )}
            </>
        )
    })
    
    const DisplayWorkHistory = React.forwardRef(() => {

        return (
            <>
            {resumeData.WorkHistory
                .map((rowItem, rowIndex) => (
                    <>
                    {(rowIndex - 1) >= 0 ?
                    <>

                    {resumeData.WorkHistory[rowIndex - 1].Company !== rowItem.Company ?
                    <><div className="content-title">
                    {rowItem.Company}
                    </div>
                    <div className="content-border"> </div>
                    </>
                    :<></>}

                    </>
                    :
                    <>
                    <div className="content-title">
                    {rowItem.Company}
                    </div>
                    <div className="content-border"> </div>
                    </>}

                    <div className="content-subtitle-rowgroup-top">
                        <div className="content-subtitle-rowgroup-bolditalic">
                        {`${rowItem.Title} •`}
                        </div>
                        <div className="content-subtitle-rowgroup-details">
                            {rowItem.TimePeriod}
                        </div>
                    </div>
                    <div className="content-subborder"> </div>

                    {resumeData.WorkHistory[rowIndex].SectionDetails.length > 0 ?
                    <>
                    {rowItem.SectionDetails
                    .map((rowSecDetItem, rowSecDetIndex) => (
                        <>
                        <div className="content-body">
                        {rowSecDetItem.Description}
                        </div>

                        {rowSecDetItem.SupportItems.length > 0 ?
                        <div className="content-body">
                            <ul style={{paddingTop: '10px'}}>
                            {rowSecDetItem.SupportItems
                            .map((rowItemList, rowIndexList) => (
                                <>
                                <li>{rowItemList.ItemDetail}</li>
                                </>
                            )) }
                            </ul>
                        </div>
                        : <><div style={{paddingBottom: '10px'}}> </div></>}
                        </>
                    ))}
                    </>
                    :<></>}

                    <div className="section-end"> </div>
                    </>
                )
            )}
            </>
        )
    })

    const DisplayEducationHistory = React.forwardRef(() => {

        return (
            <>
            {resumeData.Education
                .map((rowItem, rowIndex) => (
                    <>
                    <div className="content-title">
                    {rowItem.School}
                    </div>
                    <div className="content-subtitle-rowgroup-top">
                        <div className="content-subtitle-rowgroup-bolditalic">
                        {`${rowItem.Degree} •`}
                        </div>
                        <div className="content-subtitle-rowgroup-details">
                            {rowItem.ProgramOfStudy}
                        </div>
                    </div>
                    <div className="content-subtitle-rowgroup-details">
                        {rowItem.TimePeriod}
                    </div>
                    <div className="content-border"> </div>
                    <div className="content-body">
                        {rowItem.Details}
                    </div>

                    {rowItem.ListOfItems.length > 0 ?
                    <div className="content-body">
                        <ul style={{paddingTop: '10px'}}>
                        {resumeData.Education[rowIndex].ListOfItems
                        .map((rowItemList, rowIndexList) => (
                            <>
                            <li>{rowItemList.ItemDetail}</li>
                            </>
                        )) }
                        </ul>
                    </div>
                    : <></>}

                    <div className="section-end"> </div>
                    </>
                )
            )}   
            </>       
        )
    })

    return (
        <>
        <div className="page-container">
            <div className="page-introduction">
                <div className="introduction-picture">
                    <Image src={HeadshotImage} roundedCircle />
                </div>

                <div className="introduction-details">
                    <div className="introduction-title">
                        {`I am ${resumeData.Name}`}
                    </div>
                    <div className="introduction-subtitle">
                        {`${resumeData.WebsiteTitle}`}
                    </div>
                    <div className="introduction-details-border"> </div>

                    <div className="introduction-description">
                        <DisplayAboutMeParagraph />
                    </div>

                </div>                
            </div>

            <div className="page-introduction">
            <div className="introduction-goto-top">
                <div className="introduction-goto-link">
                    <Button variant="secondary" size="sm" href={`/solutions/histprojects`}>Projects and History</Button>
                </div>
                <div className="introduction-goto-link">
                    <Link
                        activeClass="pointofinterest"
                        to="pointofinterest"
                        spy={true}
                        smooth={true}
                        duration={700}>
                            <Button variant="warning" size="sm">Points of Interests</Button>
                    </Link>
                </div>

                {resumeData.ShowSkillsSection ?
                <div className="introduction-goto-link">
                    <Link
                        activeClass="skillset"
                        to="skillset"
                        spy={true}
                        smooth={true}
                        duration={700}>
                            <Button variant="warning" size="sm">Skills</Button>
                    </Link>
                </div>
                
                :<></>}
                <div className="introduction-goto-link">
                    <Link
                        activeClass="workhistory"
                        to="workhistory"
                        spy={true}
                        smooth={true}
                        duration={700}>
                            <Button variant="warning" size="sm">Work History</Button>
                    </Link>
                </div>
                <div className="introduction-goto-link">
                    <Link
                        activeClass="education"
                        to="education"
                        spy={true}
                        smooth={true}
                        duration={700}>
                            <Button variant="warning" size="sm">Education</Button>
                    </Link>
                </div> 
            </div>
            </div>

            <div id="pointofinterest" className="bannersec-top">
                <div className="bannersec-title">
                    <div>
                        <h3>Points of Interest</h3>
                    </div>
                    <div className="section-border">
                    </div>
                </div>
                <div className="bannersec-body">
                    <HomePointOfInterest />
                </div>
            </div>

            <div id="workhistory" className="page-introduction" style={{paddingTop: `20px`}}>
                <div className="content-title-top">
                    <div><h3>WORK SECTION</h3></div>
                    <div className="section-border"></div>
                </div>
                <div className="content-body-top">
                    <DisplayWorkHistory />
                </div>
            </div>

            {resumeData.ShowSkillsSection ?
            <div id="skills" className="page-introduction">
                <div className="resume-section-title">
                    <div className="resume-section-font">
                        <h3>Skills</h3>
                    </div>
                    <div className="section-border">
                    </div>
                </div>
                
                <div id="skillset"></div>
            </div>
            :<></>}


            <div id="education" className="page-introduction">
                <div className="content-title-top">
                    <div><h3>EDUCATION</h3></div>
                    <div className="section-border"></div>
                </div>
                <div className="content-body-top">
                    <DisplayEducationHistory />
                </div>
            </div>

            <div>
                <Footer />
            </div>

            <div>
                <FaArrowCircleUp className="scrollTop" onClick={scrollTop} style={{height: 50, display: showScroll ? 'flex' : 'none'}}/>
            </div>

        </div>
        </>
    )
}