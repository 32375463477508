import React, { forwardRef, useEffect } from "react";
import { Image } from 'react-bootstrap';
import resumeData from '../../../resume_content.json';
import './point_of_interest.css'

import GrowthImg from '../../../assets/poi/growth.png';
import LightbulbImg from '../../../assets/poi/lightbulb.png';
import LeadershipImg from '../../../assets/poi/leadership.png';
import OrangeGearsImg from '../../../assets/poi/orange_gears.png';

const HomePointOfInterest = forwardRef(() => {

    const PoiCardDetails = React.forwardRef((props?: any) => {

        return (
            <>
            {resumeData.PointsOfInterest
                .map((rowItem, rowIndex) => (
                    <>                    
                    <div className="poi-container">
                        <div className="poi-image">
                            {rowItem.SectionImage === `growth.png` ? <Image src={GrowthImg} thumbnail />:<></>}
                            {rowItem.SectionImage === `lightbulb.png` ? <Image src={LightbulbImg} thumbnail />:<></>}
                            {rowItem.SectionImage === `leadership.png` ? <Image src={LeadershipImg} thumbnail />:<></>}
                            {rowItem.SectionImage === `orange_gears.png` ? <Image src={OrangeGearsImg} thumbnail />:<></>}
                        </div>
                        <div className="poi-details">
                            <div className="poi-title">
                                {rowItem.SectionTitle}
                            </div>
                            <div className="poi-desc">
                                {rowItem.Details}
                            </div>
                        </div>
                    </div>
                    </>
                )
            )}
            </>
        )
    })

    return (
        <>
        <div className="poi-top">
            <PoiCardDetails />
        </div>
        </>
    )
})

export default HomePointOfInterest;