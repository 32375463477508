import * as d3 from 'd3';
import './skills_graph.css'

export function SkillsGraph(height, width, resumeSkillsSet) {
    var margin = {
        top: 30,
        right: 40,
        bottom: 50,
        left: 210
    },
    barWidth = width - margin.left - margin.right,
    barHeight = height - margin.top - margin.bottom;

    var svg = d3.select("#skillset")
        .append("svg")           
            .attr("width", barWidth + margin.left + margin.right)
            .attr("height", barHeight + margin.top + margin.bottom);

    var x = d3.scaleLinear()
        .domain([0, d3.max(resumeSkillsSet, function(d) { return d.Rating; })])
        .rangeRound([0, barWidth]);
        //.range([0, barWidth]);

    var y = d3.scaleBand()
        .range([barHeight, 0])
        .align(0.1)
        .domain(resumeSkillsSet.map(function(d) { return d.Title; })).padding(0.1);

    var g = svg.append("g")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    // Setting the Data on Lines
    g.append("g")
        .attr("class", "x axis")
           .attr("transform", "translate(0," + barHeight + ")")
           .call(d3.axisBottom(x).ticks(4).tickFormat((d) => {
                switch(d) {
                    case 1:
                        return `Basic Knowledge`;
                    case 2:
                        return `Some Proficiency`;
                    case 3:
                        return `Proficient`;
                    case 4:
                        return `Advanced`;
                    default:
                        return ``;
                }
                return `Hello`; //parseInt(d);
           }).tickSizeInner([-barHeight]))
           .style("font-size", "14px");
          //ORIGINAL .call(d3.axisBottom(x).ticks(5).tickFormat(function(d) { return parseInt(d); }).tickSizeInner([-barHeight]));
    
    g.append("g")
        .attr("class", "y axis")
        .call(d3.axisLeft(y))
        .style("font-size", "14px");

    g.selectAll(".bar")
        .data(resumeSkillsSet)
      .enter().append("rect")
        .attr("class", "bar")
        .attr("x", 0)
        .attr("height", y.bandwidth())
        .attr("y", function(d) { return y(d.Title); })
        .attr("width", function(d) { return x(d.Rating); });
    /*
    var svg = d3.select("#graphic").append("svg")
        .attr("width", barWidth + margin.left + margin.right)
        .attr("height", barHeight + margin.top + margin.bottom)
        .append("g")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    var x = d3.scaleLinear()
        .range([0, barWidth])
        .domain([0, d3.max(resumeSkillsSet, function (d) {
            return d.Rating;
        })]);
    */
}