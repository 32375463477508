import { Styles } from "react-select"

export const reactSelectCustomStyles: Partial<Styles> = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px dotted orange',
        color: state.isSelected ? 'orange' : 'black',
        padding: 8,
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';
    
        return { ...provided, opacity, transition };
    }
}