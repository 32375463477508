import React, { useState } from 'react';
import {  Nav, Navbar, NavDropdown, Button, Image , Row, Col } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import { FaBars  } from "react-icons/fa";   //FaList
import './corestyles.css';

/*    
    <Nav.Link href={`https://bagalaysolutions.net/FileShare/Resume_Projects202101.pdf`}>Resume</Nav.Link>
*/

const TopNav = () => {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-device-width: 1224px)'
    })
    const isBigScreen = useMediaQuery({ query: '(min-device-width: 1824px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 1224px)'
    })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

    const DisplayNavigationDesktop = React.forwardRef(() => {
        return (
            <Navbar bg="primary" variant="dark">
                <Navbar.Brand href={`/solutions`}>Bagalay Solutions</Navbar.Brand>

                <Nav>
                    <Nav.Link href={`/solutions/histprojects`}>Projects</Nav.Link>
                </Nav>

                <Nav className="mr-auto">          

                <NavDropdown title="Hosted Solutions" id="collasible-nav-dropdown">
                    <NavDropdown.Item href={`https://bagalaysolutions.net/estimation`}>Estimation Calculator</NavDropdown.Item>
                    <NavDropdown.Item href={`https://bagalaysolutions.net/projtracker`}>Project Tracker</NavDropdown.Item>
                </NavDropdown>
                </Nav>
            </Navbar>
        )
    })

    const DisplayNavigationMobile = React.forwardRef(() => {
        return (
            <Navbar bg="primary" variant="dark">
                
                <Nav>
                    <NavDropdown title={<FaBars size={`25`} color={`#ffa500`} />} id="collasible-nav-dropdown">
                        <NavDropdown.Item href={`/solutions`} >Home</NavDropdown.Item>
                        <NavDropdown.Divider />

                        <NavDropdown.Item href={`/solutions/histprojects`}>Projects</NavDropdown.Item>
                    </NavDropdown>
                </Nav>
                
                <Nav style={{paddingLeft: `15px`}}>
                    <Navbar.Brand href={`/solutions`}>Bagalay Solutions</Navbar.Brand>
                </Nav>                
            </Navbar>
        )
    })

    return (
    <div>
        {!isTabletOrMobile ? <DisplayNavigationDesktop />
        : <DisplayNavigationMobile />}
    </div>
    );
}

export default TopNav;